import React from 'react';
import RouteViewPage from "../Pages/route-view-page";
import {useParams} from "react-router-dom"
import { LinearProgress } from '@mui/material';
import GetEvent from "../Drive/get-event";

function Salt() {
    const data_sheet_id = '1W17G92IV0bZ4qfyN3mTAizKjaLS-MlDCahFMrLJm7pg';
    const data_csv_url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR2RUM-nxMixGdmRputIOPspV7JWaYTSAC-0Wc7vPAXSjoLoJXHN-EeYt-T-LMazIXhWb8zvyi6UiJt/pub?output=csv';
    const script_id = "AKfycbyQY3Nkn4mM4c2Bw9tnGG2UKvyZKrL2xy6VcWXuYn1FPk_a-9Fw1_zZih_SqiQRhhLjTA";
    // ACTION: Change to this path requires update to public cleanup routes sheet getImages script
    const base_image_upload_path = '/uploads/${event}/snow/properties/';
    const [image_upload_path, setImageUploadPath] = React.useState("");

    React.useEffect(() => {
        GetEvent(script_id, handleUpdateEvent)
    }, [])

    const handleUpdateEvent = (result) => {
        if (result.succeeded) {
            let newPath = base_image_upload_path.replace("${event}", result.event)
            setImageUploadPath(newPath);
        }
    }

    let params = useParams();
    let id = params.id.replaceAll("_", " ");

    return (
        <>
        {image_upload_path == "" ? (
            <LinearProgress />
        ):(
            <RouteViewPage id={id} data_sheet_id={data_sheet_id} data_csv_url={data_csv_url} script_id={script_id} image_upload_path={image_upload_path}/>
        )}
        </>
        
    )
}

export default Salt