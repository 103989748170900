const MarkWeekNotPaid = async (start, end, handleDone) => {
    let data = {
        start: start,
        end: end,
    };
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/markWeekNotPaid`, requestOptions)

    let result = await response.json();
    handleDone(result);
    return result;
}

export default MarkWeekNotPaid;