const SubmitPartnerForm = async (body, image, handleDone) => {

    const data = new FormData();
    data.append('image', image);
    data.append('contents', JSON.stringify(body));

    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: data,
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/submit-partner-form`, requestOptions);

    if (response.ok) {
        handleDone();
    }
}

export default SubmitPartnerForm;