import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import updateSheetValues from "./update-google-sheet"
import Modal from '@mui/material/Modal';
import WebcamCapture from './Util/web-camera.js'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
    uploadButton: {
        fontFamily: 'Poppins',
        borderRadius: '5px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '3px',
        paddingLeft: '3px',
        // backgroundColor: 'white',
        color: 'black',
        fontSize: '16px',
        border: 'none',
    }
}));

function ImageUpload(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
      props.refresh();
    };

    const handleClick = () => {
        handleOpen();
    }

    return (
        <div>
            <Button
                variant="contained"
                color="success"
                className = {classes.uploadButton}
                onClick={handleClick}>
                Complete
            </Button>
            <Modal
                hideBackdrop
                open={open}
            >
                    <WebcamCapture {...props}  close={handleClose} updateCache={props.updateCache} type={props.type} image_upload_path={props.image_upload_path} data_sheet_id={props.data_sheet_id}/>
            </Modal>
        </div>
    )
}

export default ImageUpload;