
async function UpdateServiceRange(job_id, serviceRange) {
    let data = {
        job_id: job_id,
        serviceRange: serviceRange, 
    };
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: data,
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/updateServiceRange`, requestOptions)
    return ;
}

export default UpdateServiceRange;