import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { Typography, Select, MenuItem, Divider, Box, IconButton } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import GetComplete from '../../API/get-complete';
import WeeklyIncome from '../../API/weekly-income';
import MarkPaid from '../../API/mark-paid';
import MarkWeekPaid from '../../API/mark-week-paid';
import MarkWeekNotPaid from '../../API/mark-week-not-paid';
import JobRow from './job-row';
import EmptyCheckbox from '../../../assets/empty_checkbox.png';
import FilledCheckbox from '../../../assets/filled_checkbox.png';

const useStyles = makeStyles(() => ({ 
    tableHeader: {
        marginTop: '15px',
        marginBottom: '5px'
    },
    tableColumnHeader: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '5vw',
        lineHeight: '30px',
        /* identical to box height */
    },
    header: {
        backgroundColor: '#D9D9D9',
    },
    rowText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '3vw',
        lineHeight: '22px',
    },
    firstLine: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
        marginRight: '20px',
        paddingTop: '10px',
    },
    exit: {
        marginLeft: 'auto',
    },
    name: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '32px',
    },
    body: {
        textAlign: 'center',
    },
    statusText: {
        color: '#6D6D6D',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '30px',
    },
    callContainer: {
        marginRight: '20px',
        marginLeft: '20px',
        marginBottom: '20px',
        backgroundColor: '#D9D9D9',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    quoteBody: {
        textAlign: 'left',
        marginLeft: '20px',
        marginRight: '20px',
    },
    sectionText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
    },
    descriptionContainer: {
        backgroundColor: '#D9D9D9',
        padding: '10px',
        marginBottom: '20px',
        overflowWrap: 'auto'
    },
    text: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px', 
    },
    lineItemContainer: {
        display: 'flex',
        alignItems: 'center',
        // marginTop: '10px',
        marginBottom: '10px',
    },
    totalContainer: {
        display: 'flex',
        width: '30vw',
        paddingTop: '3px',
        marginLeft: 'auto',
    },
    addLineItem: {
        display: 'flex',
        alignItems: 'center',
        // marginLeft: '25%',
        margin: 'auto',
    },
    addLineContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#D9D9D9',
        borderRadius: '5px',
        width: '50vw',
        marginLeft: '25%',
        marginTop: '30px',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    textfield: {
        width: '90vw',
        paddingBottom: '20px',
    },
    rightAlign: {
        '& .MuiInput-root': {
          textAlign: 'right',
        },
      },
    actionContainer: {
        position: 'absolute',
        bottom: '20px',
        width: '100%',
    }
}))

function IncomeAdmin(props) {
    const classes = useStyles();
    const [jobs, setJobs] = React.useState([])

    React.useEffect(() => {
        console.log('jobs', jobs);
        WeeklyIncome(setJobs);
    }, [])

    const getPartnerPayment = (q) => {
        let payment = 0
        for (let i = 0; i < q.LineItems.length; i++) {
            payment += q.LineItems[i].price;
        }
        return payment;
    }

    const handleClick = (event, q) => {
        event.stopPropagation();
        if (q.paid) {
            MarkWeekNotPaid(q.start, q.end, setJobs);
        }
        else {
            MarkWeekPaid(q.start, q.end, setJobs);
        }
    }

    return (
        <>
        <div className={classes.tableHeader}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography 
                    style={{marginLeft: '20px'}}
                    className={classes.tableColumnHeader}
                    >Week</Typography>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center', paddingLeft: 0, paddingRight: 0}}>
                    <Typography 
                    className={classes.tableColumnHeader} 
                    >Partner Total</Typography>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'center', paddingLeft: 0, paddingRight: 0}}>
                    <Typography 
                    className={classes.tableColumnHeader} 
                    >Revenue</Typography>
                </Grid>
            </Grid>
        </div>
        <Divider style={{height: '1px', backgroundColor: 'black', marginBottom: '20px'}}></Divider>
        {jobs.map((q) => {
            return (
                <>
                <Grid container spacing={2}>
                <Grid item xs={5} style={{margin: 'auto'}}>
                    <div style={{marginLeft: '20px'}}>
                        <Typography className={classes.rowText}>{q.week_start} - {q.week_end}</Typography>
                    </div>
                </Grid>
                <Grid item xs={3} style={{textAlign: 'right', margin: 'auto'}}>
                    <Grid container spacing={2}>
                    <Grid item xs={6} style={{textAlign: 'right', margin: 'auto'}}>
                        <Typography className={classes.rowText}>${q.partner_payment}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton 
                            onClick={(event) => handleClick(event, q)}
                        >
                            {q.paid ? <img src={FilledCheckbox}></img>:<img src={EmptyCheckbox}></img>}
                        </IconButton>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right', margin: 'auto', paddingRight: '20px'}}>
                    <Typography className={classes.rowText}>${q.revenue}</Typography>
                </Grid>
                </Grid>
                <Divider style={{height: '1px', backgroundColor: 'black', marginBottom: '20px', marginTop: '20px'}}></Divider>
                </>
            )
        })}
        </>
    )

}

export default IncomeAdmin;