const translations = 
{ partners: 
    [ { english: 'Residential Snow Removal Job Application',
        spanish: 'Solicitud de empleo de eliminación de nieve residencial',
        portoguese: 'Aplicação de trabalho residencial de Remival Snow' },
      { english: 'Select your language to view the form:',
        spanish: 'Seleccione su idioma para ver el formulario:',
        portoguese: 'Selecione seu idioma para visualizar o formulário:' },
      { english: 'Do you have a truck with a snow plow?',
        spanish: '¿Tienes un camión con arado de nieve?',
        portoguese: 'Você tem um caminhão com um arado de neve?' },
      { english: 'Let us know once you have a truck with a snow plow to be considered.',
        spanish: 'Háganos saber una vez que tenga un camión con un arado de nieve para ser considerado.',
        portoguese: 'Deixe -nos saber onze, você tem um caminhão com um arado de neve para ser considerado.' },
      { english: 'How many trucks do you have?',
        spanish: '¿Cuántos camiones tienes?',
        portoguese: 'Quantos caminhões você tem?' },
      { english: 'Select number of trucks',
        spanish: 'Seleccione el número de camiones',
        portoguese: 'Selecione o número de caminhões' },
      { english: 'Do you have a snowblower?',
        spanish: '¿Tienes un soplador de nieve?',
        portoguese: 'Você tem um soprador de neve?' },
      { english: 'Do you have at least 1 additional person who will work with you?',
        spanish: '¿Tiene al menos 1 persona adicional que trabaje con usted?',
        portoguese: 'Você tem pelo menos uma pessoa adicional que o trabalhará?' },
      { english: 'How much experience do you have with professional snow removal?',
        spanish: '¿Cuánta experiencia tienes con la eliminación profesional de nieve?',
        portoguese: 'Quantas experiências você tem com a remoção profissional de neve?' },
      { english: 'Select experience',
        spanish: 'Experiencia seleccionada',
        portoguese: 'Selecione Experiência' },
      { english: 'What is your name?',
        spanish: '¿Cómo te llamas?',
        portoguese: 'Qual o seu nome?' },
      { english: 'What is your address?',
        spanish: '¿Cuál es su dirección?',
        portoguese: 'Qual é o seu endereço?' },
      { english: 'What is your email?',
        spanish: '¿Cuál es tu e-mail?',
        portoguese: 'Qual é o seu email?' },
      { english: 'What is your phone number?',
        spanish: '¿Cuál es tu número de teléfono?',
        portoguese: 'Qual é o seu número de telefone?' },
      { english: 'Please select all languages that you can speak, read, and understand fluently:',
        spanish: 'Seleccione todos los idiomas que pueda hablar, leer y comprender con fluidez:',
        portoguese: 'Ple' },
      { english: 'Do you have a Social Security Number (SSN) or an Individual Taxpayer Identification Number (ITIN) or have a legal business with an Employer Identification Number (EIN)?',
        spanish: '¿Tiene un número de Seguro Social (SSN) o un número de identificación de contribuyentes individual (ITIN) o tiene un negocio legal con un número de identificación del empleador (EIN)?',
        portoguese: 'Você tem um número de Seguro Social (SSN) ou um número de identificação de contribuintes individuais (ITIN) ou terá negócios legais com um número de identificação de entidade (EIN)?' },
      { english: 'Are you able to provide a W-9 tax form?',
        spanish: '¿Puede proporcionar un formulario de impuestos W-9?',
        portoguese: 'Você é Uble para fornecer formulário de imposto W-9?' },
      { english: 'Do you have a valid state-issued driver\'s license?',
        spanish: '¿Tiene una licencia de conducir emitida por el estado válida?',
        portoguese: 'Você tem uma carteira de motorista válida emitida pelo estado?' },
      { english: 'Do you have snow plowing insurance or general liability insurance for your business?',
        spanish: '¿Tiene un seguro de arado de nieve o un seguro de responsabilidad civil general para su negocio?',
        portoguese: 'Você tem seguro de lavagem de neve ou seguro de responsabilidade geral para sua empresa?' },
      { english: 'Will you be working with any other snow removal companies this winter?',
        spanish: '¿Trabajará con otras compañías de eliminación de nieve este invierno?',
        portoguese: 'Você trabalhará com outras empresas de remoção de neve neste inverno?' },
      { english: 'How can we pay you?',
        spanish: '¿Cómo podemos pagarle?',
        portoguese: 'Como podemos pagar você?' },
      { english: 'Please specify the number of trucks.',
        spanish: 'Especifique el número de camiones.',
        portoguese: 'Especifique o número de caminhões.' },
      { english: 'Please specify whether you have a snowblower.',
        spanish: 'Especifique si tiene un soplador de nieve.',
        portoguese: 'Especifique quem você tem um soprador de neve.' },
      { english: 'Please specify whether you have an additional person.',
        spanish: 'Especifique si tiene una persona adicional.',
        portoguese: 'Especifique quem você tem uma pessoa adicional.' },
      { english: 'Please specify your experience.',
        spanish: 'Especifique su experiencia.',
        portoguese: 'Especifique sua experiência.' },
      { english: 'Please enter your name.',
        spanish: 'Por favor, escriba su nombre.',
        portoguese: 'Por favor, insira seu nome.' },
      { english: 'Please enter your address.',
        spanish: 'Ingrese su dirección.',
        portoguese: 'Por favor, insira seu endereço.' },
      { english: 'Please enter your email.',
        spanish: 'Por favor introduzca su correo electrónico.',
        portoguese: 'Por favor introduza o seu e-mail.' },
      { english: 'Please enter your phone number.',
        spanish: 'Por favor, introduzca su número de teléfono.',
        portoguese: 'Por favor, digite seu número de telefone.' },
      { english: 'Please specify atleast one language that you speak fluently.',
        spanish: 'Especifique al menos un idioma que hable con fluidez.',
        portoguese: 'Ple' },
      { english: 'Please specify whether you have an SSN, ITIN or EIN.',
        spanish: 'Especifique si tiene un SSN, ITIN o EIN.',
        portoguese: 'Especifique quem você tem um ssn, iin ou ein.' },
      { english: 'Please specify whether you can provide a w9.',
        spanish: 'Especifique si puede proporcionar un W9.',
        portoguese: 'Especifique o que você pode fornecer W9.' },
      { english: 'Please specify whether you have a driver\'s license.',
        spanish: 'Especifique si tiene una licencia de conducir.',
        portoguese: 'Especifique quem você tem uma carteira de motorista.' },
      { english: 'Please upload a picture of your driver\'s license.',
        spanish: 'Sube una imagen de tu licencia de conducir.',
        portoguese: 'Envie uma foto da sua carteira de motorista.' },
      { english: 'Please specify whether you have insurance.',
        spanish: 'Especifique si tiene seguro.',
        portoguese: 'Por favor, especifique o que você tem seguro.' },
      { english: 'Please specify whether you will be working with other companies.',
        spanish: 'Especifique si trabajará con otras compañías.',
        portoguese: 'Por favor, especifique o que você trabalhará com outras empresas.' },
      { english: 'Please specify payment methods.',
        spanish: 'Especifique los métodos de pago.',
        portoguese: 'Especifique os métodos de pagamento.' },
      { english: 'Please specify your cashapp details.',
        spanish: 'Especifique sus detalles de CashApp.',
        portoguese: 'Especifique seus detalhes do CashApp.' },
      { english: 'Please specify your venmo details.',
        spanish: 'Especifique sus detalles de Venmo.',
        portoguese: 'Especifique seus detalhes do Venmo.' },
      { english: 'Please specify your zelle details.',
        spanish: 'Especifique sus detalles de Zelle.',
        portoguese: 'Especifique os detalhes da sua Zelle.' } ] }

export default translations;