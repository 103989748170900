
async function UpdateSurcharge(job_id, surcharge, setSurcharge) {
    let charge = (surcharge == 0) ? "0": surcharge;
    let data = {
        job_id: job_id,
        surcharge: charge, 
    };
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/updateSurcharge`, requestOptions)
    let result = await response.json();
    result = (result == '0') ? "": result;
    setSurcharge(result);
    return result;
}

export default UpdateSurcharge;