import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { fontSize } from '@mui/system';
import SwipeableImageGallery from '../swipeable-image-gallery';
import { GrClose, GrMap } from 'react-icons/gr'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { VscChromeClose } from 'react-icons/vsc'
import InfoModal from '../info-modal'
import ImageUpload from '../image-upload'
import { CgDanger } from 'react-icons/cg';
import { Buffer } from 'buffer';

const useStyles = makeStyles(() => ({
    container: {
        // margin: '10px',
    },
    no_bullet: {
        listStyle:'None',
    },
    routeBlock: {
        display: 'block',
        marginBottom: '10px',
        float: 'left',
    },
    topRow: {
        // display: 'inline',
        marginLeft: '15px'
    },
    routeHeader: {
        marginTop: '0px',
        marginBottom: '0px',
        fontWeight: '700',
        letterSpacing: '-1px',
    },
    completedBlock: {
        marginTop: '0px',
        marginBottom: '0px',
        fontWeight: '400',
    },
    link: {
        textDecoration: 'none',
    },
    leftBlock: {
        float: 'right',
        marginRight: '10px',
    },
    completionPercentage: {
        marginTop: '0px',
        marginBottom: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
        fontSize: '60px',
    },
    notesButton: {
        borderRadius: '5px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '3px',
        paddingLeft: '3px',
        backgroundColor: 'white',
        color: 'black',
        fontSize: '16px'
    },
    closeButtonContainer: {
        float: 'right',
        marginTop: '20px',
        paddingRight: '0px',
    },
    closeButton: {
        alignItems: 'right',
        justifyContent: 'right',
        minWidth: '0px',
        padding: '0px',
    },
    header: {
        float: 'left',
        width: '75%',
        marginBottom: '0px',
    },
    modalHeader: {
        display: 'inline-block',
        width: '100%',
        marginBottom: '0px',
    },
    notes: {
        width: '100%',
    },
  }));  

 const ModalStyle = {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75vw',
    height: '70vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    overflowY: 'auto',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

const StyledTable = styled(Table)(({ theme }) => ({
    borderRadius: '0px',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderRadius: '0px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Poppins',
    letterSpacing: '-1px',
    fontWeight: '400',
    padding: '0px',
    // fontSize: '30px',
    [`&.${tableCellClasses.head}`]: {
        paddingRight: '10px',
        paddingLeft: '10px',
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    [`&.${tableCellClasses.body}`]: {
        // paddingTop: '2px',
        // paddingBottom: '2px',
        // paddingRight: '5px',
        // padding: '0px',
        fontSize: 16,
        textAlign: 'center', 
    },
    }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    }));

const StyledTableRowGreen = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    backgroundColor: '#93c47d',
    }));

const StyledTableRowComplaint = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    backgroundColor: '#ea9999',
    }));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: '0px',
    width: '100%',
    }));

const isComplete = (completed) => {
    if (completed == "yes") {
        return true
    }
    return false
}

function RouteViewPage(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [addressCache, setAddressCache] = useState({})
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    function getData() {
        Papa.parse(props.data_csv_url, {
        download: true,
        header: true,
        complete: (results) => {
            setData(results.data);
        }
        });
    }

    function refreshPage() {
        getData();
    }

    const setAddress = (address) => {
        let newcache = {...addressCache};
        newcache[address] = true;
        setAddressCache(newcache);
    }

    useEffect(() => {
        getData();
        document.title = props.id;
      }, []);

    const checkCache = () => {
        var length = routes.length;
        for (let i = 0; i < length; i++) {
            if (addressCache[routes[i].Address] != undefined) {
                routes[i].Completed = "yes";
            }
        }
        return routes;
    }

    let routes = Array.from(data);
    routes = routes.filter(route => {return route.Driver.toLowerCase() === props.id.toLowerCase();});
    routes = checkCache(routes);
    let numRoutes = routes.length;
    let completed = routes.filter(route => {return route.Completed === "yes"});
    let numCompleted = completed.length;
    let decimal = (numRoutes == 0) ? 0 : numCompleted/numRoutes;
    let percentage = Math.trunc(decimal * 100);

    //https://developers.google.com/maps/documentation/urls/ios-urlscheme
    //https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
    //https://codepen.io/colinlord/post/native-map-apps-from-the-mobile-browser
    const mapsSelector = (url, address) => {
        let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
        if 
        ((platform.indexOf("iPhone") != -1) || 
        (platform.indexOf("iPad") != -1)    ||
        (platform.indexOf("MacIntel") != -1))
            //comgooglemaps://?q=4+Partridge+Way,+Holliston,+MA+01746/
            //https://maps.apple.com/?q=4+Partridge+Way,+Holliston,+MA+01746/
            return "https://maps.apple.com/?q=" + address.replaceAll(" ","+")
            
        else /* else use Google */
            return "https://maps.google.com/maps?q=" + address.replaceAll(" ","+")
    }

    const trimAddress = (address) => {
        let trimposition = address.search(", MA")
        return address.substring(0,trimposition)
    }

    return (
        <div>
        <div className={classes.topRow}>
            <div className={classes.routeBlock}>
                <h1 className={classes.routeHeader}>
                    {props.id}
                </h1> 
                <h3 className={classes.completedBlock}>
                    {numCompleted}/{numRoutes} completed
                </h3>
            </div>
            <div className={classes.leftBlock}>
                <h1 className={classes.completionPercentage}>
                    {percentage}%
                </h1>
            </div>
        </div>
            <StyledTableContainer component={Paper}>
            <StyledTable>
            <StyledTableHead>
                <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell >Address</StyledTableCell>
                <StyledTableCell >Info</StyledTableCell>
                <StyledTableCell >Photos</StyledTableCell>
                </TableRow>
            </StyledTableHead>
            <TableBody>
                {routes.map((route) => {
                    let notComplaint = (route.Stop_Number != "");
                    let stop_number = (notComplaint) ? route.Stop_Number : "**";
                    let notes = (notComplaint) ? route.Notes : route.Complaint;
                    let spanish_notes = (notComplaint) ? route.Notes_Spanish : route.Complaint_Spanish;
                    let portuguese_notes = (notComplaint) ? route.Notes_Portuguese : route.Complaint_Portuguese;
                    let type = (notComplaint) ? "Normal": "Complaint";
                    if (isComplete(route.Completed)) {
                        return (<StyledTableRowGreen
                            key={route.driver}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {notComplaint ? (
                            <StyledTableCell >
                            {stop_number}
                            </StyledTableCell>) : 
                            (<StyledTableCell >
                                <CgDanger size={30}/>
                                </StyledTableCell>)}
                            <InfoModal href={mapsSelector(route.Link, route.Address)} address={route.Address} trimmedAddress={trimAddress(route.Address)} notes={notes} spanish_notes={spanish_notes} portuguese_notes={portuguese_notes} isComplete={true} notComplaint={notComplaint}/>
                            <StyledTableCell >
                                <ImageUpload address={route.Address} data={data} driver={route.Driver} refresh={refreshPage} data_csv_url={props.data_csv_url} updateCache={setAddress} type={type}  {...props}>
                                </ImageUpload>
                            </StyledTableCell>
                        </StyledTableRowGreen>)}
                    else if (stop_number == "**") {
                        return (<StyledTableRowComplaint
                            key={route.driver}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row">
                            <CgDanger size={30}/>
                            </StyledTableCell>
                            <InfoModal href={mapsSelector(route.Link, route.Address)} address={route.Address} trimmedAddress={trimAddress(route.Address)} notes={notes} spanish_notes={spanish_notes} portuguese_notes={portuguese_notes} isComplete={false} notComplaint={notComplaint}/>
                            <StyledTableCell >
                                <ImageUpload address={route.Address} data={data} driver={route.Driver} refresh={refreshPage} data_csv_url={props.data_csv_url} updateCache={setAddress} type={type}  {...props}>
                                </ImageUpload>
                            </StyledTableCell>
                        </StyledTableRowComplaint>)
                    }
                    else {
                        return (<StyledTableRow
                            key={route.driver}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row">
                            {stop_number}
                            </StyledTableCell>
                            <InfoModal href={mapsSelector(route.Link, route.Address)} address={route.Address} trimmedAddress={trimAddress(route.Address)} notes={notes} spanish_notes={spanish_notes} portuguese_notes={portuguese_notes} isComplete={false} notComplaint={notComplaint}/>
                            <StyledTableCell >
                                <ImageUpload address={route.Address} data={data} driver={route.Driver} refresh={refreshPage} data_csv_url={props.data_csv_url} updateCache={setAddress} type={type}  {...props}>
                                </ImageUpload>
                            </StyledTableCell>
                        </StyledTableRow>)
                    }
                })}
            </TableBody>
            </StyledTable>
        </StyledTableContainer>
      </div>
    )
}

export default RouteViewPage;
