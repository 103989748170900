
async function  GetEvent(sheet_id, updateDone) {
    var url = `https://script.google.com/macros/s/${sheet_id}/exec?method=getEvent`
    fetch(url).then(response => {
        let result = response.json();
        result.then(result => updateDone(result));
    })
}

export default GetEvent;
