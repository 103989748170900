import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { Box, Typography } from '@material-ui/core';
import New_Pic from '../../assets/new_pic.png'
import uploadLandscapingImage from '../Upload/upload-landscaping-image';
// import UploadLandscapingPicture from '../API/upload-landscaping-pictures';

const useStyles = makeStyles(() => ({ 
    container: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gap: '4vw',
        justifyContent: 'space-between',
    },
    imageContainer: {
        width: '25vw',
        height: '25vw',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    sectionText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
    },

}))

function ImageFile(url, file) {
    this.url = url;
    this.file = file;
    this.progress = 1;
    this.uploaded = false;
  }

function PictureSection(props) {
    const classes = useStyles();
    const [pictures, setPictures] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const [complete, setComplete] = React.useState(false);

    React.useEffect(() => {
        getPicturesFromUpload();
    }, [])

    React.useEffect(() => {
        if (props.download && props.download > 0) {
            downloadFiles();
        }
    }, [props.download])

    const getPicturesFromUpload = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Basic YXBpa2V5OnB1YmxpY19XMTQyaFZYeGc2YVd1NkVZUDRkVVY2QVNQSk5Y");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        // UPLOAD IO TAG: must update folder for each snowstorm.
        var path = `/uploads/landscaping/${props.job_id}/${props.status}`
        fetch(`https://api.upload.io/v2/accounts/W142hVX/folders/children?folderPath=${path}`, requestOptions)
        .then(response => response.json().then((data) => {
        if (data != undefined) {
            let imgs = data.children.map(chil => chil.fileUrl);
            if (imgs != undefined && imgs[0] != undefined) {
            setPictures(imgs);
            }
        }
        // setDownloading(false);
        }))
        .catch(error => console.log('error', error));
    }

    const handleImageChange = (event) => {
        var new_images = [...images];
        for(let i = 0; i < event.target.files.length; i++) {
          let file = event.target.files[i]
          let url = URL.createObjectURL(file)
          let imagefile = new ImageFile(url, file);
          new_images.unshift(imagefile);
        }
        setImages(new_images);
        let length = new_images.length
        var path = `/uploads/landscaping/${props.job_id}/${props.status}`
        for (let i= 0; i < length; i++) {
            uploadLandscapingImage(new_images[i].file, setProgress, i, path);
            // UploadLandscapingPicture('address', 'job_id', 'before', new_images[i].file);
        }
    }

    const setProgress = (progress, index) => {
        let newImages = [...images];
        if (newImages[index] != undefined) {
            newImages[index].progress = progress
            setImages(newImages);
            if (allComplete()) {
                setComplete(true);
                getPicturesFromUpload();
                setImages([]);
            }
        }
    }

    const allComplete = () => {
        let complete = true;
        images.forEach((img) => {
          if (img.progress != 100) {
            complete=false;
          }
        })
        
        return complete;
      }

    const hasPictures = () => {
        if (pictures.length > 0 && props.setHasPictures) {
            props.setHasPictures(true);
        }
        if (props.admin) {
            return pictures.length > 0;
        }
        else {
            return true;
        }
    }

    const downloadFiles = async () => {
        try {
          for (let i = 0; i < pictures.length; i++) {
            const filename = `${props.addr}_${props.status}_${i + 1}.jpg`;
            const url = getWatermarkedImage(pictures[i]);
            
            await downloadFile(url, filename);
          }
        } catch (error) {
          console.error('Error occurred while downloading files:', error);
        }
      };
      
    const downloadFile = (url, filename) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
    
        xhr.onload = function () {
        if (xhr.status === 200) {
            const blob = new Blob([xhr.response], { type: 'image/jpeg' });
            const url = URL.createObjectURL(blob);
    
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.target = '_blank';
            link.style.display = 'none';
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            URL.revokeObjectURL(url);
    
            resolve();
        } else {
            reject(new Error(`Failed to download file: ${filename}`));
        }
        };
    
        xhr.onerror = function () {
        reject(new Error(`Error occurred while downloading file: ${filename}`));
        };
    
        xhr.send();
    });
    };

    const getWatermarkedImage = (file) => {
        let slashes = file.split("/");
        let underscores = slashes[slashes.length - 1].split("_");
        // let text = `${storm}`;
        let text = "";
        if (underscores.length == 6) {
            let day = parseInt(underscores[0]);
            let month = parseInt(underscores[1]);
            let year = parseInt(underscores[2]);
            let hour = parseInt(underscores[3]);
            let minute = parseInt(underscores[4]);
            console.log(day, month, year, hour, minute)
            let d = new Date(Date.UTC(year, month-1, day, hour, minute, 0,0));
            // let diff = d.getTimezoneOffset();
            // let offset = diff / 60;
            // let hour = (parseInt(underscores[0]) - offset) %24;

            let options = {
                timeZone: 'America/New_York',
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              };

            let estDateString = d.toLocaleString('en-US', options);

            var watermark1 = `image=/uploads/templates/blank_bar.png&gravity=bottom-left&layer-opacity=75`;
            var watermark2 = `text=${estDateString}&blend=source-atop&color=FF5F1F&font-size=1000&layer-opacity=100&font-weight=900&gravity=bottom-left&padding=25`;
            let path = `${file.replace('/raw/','/image/')}?${watermark1}&${watermark2}`;
            return path;
        }
        
        return file;
    }
    
    return(
        <>
        {hasPictures() && <Typography className={classes.sectionText} style={{paddingBottom: '10px'}}>{props.header}</Typography>}
        <Box className={classes.container}>
            {pictures.map(p => {
                return (
                    <Box className={classes.imageContainer}>
                        <img src={p} className={classes.image}></img>
                    </Box>
                )
            })}
            {images.map(i => {
                return (
                    <Box className={classes.imageContainer}>
                        <img src={i.url} className={classes.image}></img>
                    </Box>
                )
            })}
            {!props.admin && <Box className={classes.imageContainer}>
            <label>
                <input 
                multiple
                type="file" 
                accept="image/*" 
                onChange={handleImageChange}
                className={classes.input}
                style={{display: 'none'}}
                />
                <div className={classes.circle}>
                <img src={New_Pic}></img>
                </div>
            </label>
            </Box>}
        </Box>
        </>
    )
}

export default PictureSection;