import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { GrClose } from 'react-icons/gr';

function ImageFile(url, file) {
    this.url = url;
    this.file = file;
  }

const useStyles = makeStyles(() => ({
    container: {
        // display: 'relative'
        display: 'grid',
        textAlign: 'center',
        justifyContent: 'center',
    },
    img: {
      marginTop: '10px',
      width: '90vw',
      height: '90vw',
      borderRadius: '20px',
      objectFit: 'cover',
      dropShadow: '0 10px 5px lightblue',
      gridColumn: '1',
      gridRow: '1',
    },
    closeCircle: {
        marginTop: '15px',
        marginRight: '5px',
        display: 'flex',
        // float: 'right',
        justifySelf: 'end',
        right: '10px',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: 'white',
        gridColumn: '1',
        gridRow: '1',
    }, 
    close: {
        margin: 'auto',
        display: 'block',
    }
    }));

function ImageSquare(props) {
    const classes = useStyles();

    const handleClick = () => {
        props.delete(props.index);
    }

    return (
        <div className={classes.container}>
            <img className={classes.img} src={props.img.url} />
            <div className={classes.closeCircle}>
                <GrClose className={classes.close} onClick={handleClick} size={20}/>
            </div>
        </div>
    )
}

export default ImageSquare;