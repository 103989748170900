import React from 'react';
import RouteViewPage from "../Pages/route-view-page";
import {useParams} from "react-router-dom"
import { LinearProgress } from '@mui/material';
import GetEvent from "../Drive/get-event";

function Lawns() {
    const data_sheet_id = '1zDfF_j7P1SmDIvZPSxHfxdSEeOjZ82B67u19BW-zbeY';
    const data_csv_url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ3CFTgEOS9J-u0JZ6tGzM6U9rwwYxAsEJghR9OMPzzINoFsP3PSXxLNbhE7NV5VNo6YsNwvxZAVSBq/pub?output=csv';
    const script_id = "AKfycbzMC7BT3piMSW-o2_yOxgPKWszkx706rYDAxp7PJvP9vmUKPDo1745ziBJWPtXNdot7";
    // ACTION: Change to this path requires update to public cleanup routes sheet getImages script
    const base_image_upload_path = '/uploads/${event}/lawns/properties/';
    const [image_upload_path, setImageUploadPath] = React.useState("");

    React.useEffect(() => {
        GetEvent(script_id, handleUpdateEvent)
    }, [])

    const handleUpdateEvent = (result) => {
        if (result.succeeded) {
            let newPath = base_image_upload_path.replace("${event}", result.event)
            setImageUploadPath(newPath);
        }
    }

    let params = useParams();
    let id = params.id.replaceAll("_", " ");

    return (
        <>
        {image_upload_path == "" ? (
            <LinearProgress />
        ):(
            <RouteViewPage id={id} data_sheet_id={data_sheet_id} data_csv_url={data_csv_url} script_id={script_id} image_upload_path={image_upload_path}/>
        )}
        </>
        
    )
}

export default Lawns
