import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { Typography, Select, MenuItem, Divider } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import QuoteRequested from '../Bodies/quote-requested';
import PendingCustomerApproval from '../Bodies/pending-approval';
import Approved from '../Bodies/approved';
import Completed from '../Bodies/completed';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import faviconUrl from '../../assets/weberwelt_logo.ico'
import faviconIco from '../../assets/favicon.ico'

const useStyles = makeStyles(() => ({ 
    header: {
        backgroundColor: '#D9D9D9',
        paddingTop: '10px',
    },
    title: {
        marginLeft: '20px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '8vw',
    },
    title2: {
        marginLeft: '20px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '6vw',
    },
    dropdownContainer: { 
        textAlign: 'center',
    },
    dropdown: {
        marginTop: '10px',
        marginBottom: '20px',
        // paddingTop: '8px', 
        // paddingBottom: '8px', 
        // paddingLeft: '20px',
        // paddingRight: '20px',
        fontFamily: 'Poppins',
        fontSize: '5vw',
        backgroundColor: '#A9A9A9',
        // borderRadius: '6px',
        position: 'relative',
    },
    tableHeader: {
        marginTop: '15px',
        marginBottom: '5px'
    },
    tableColumnHeader: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '5vw',
        lineHeight: '30px',
        /* identical to box height */
    }
}))


function Landscaper(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState("quote-requested");

    const handlePageChange = (event, newValue) => {
        setPage(newValue);
    }

    React.useEffect(() => {
        document.title = "Landscaper - Adam";
        const setFavicon = (faviconUrl) => {
            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
              favicon.href = faviconUrl;
            }
          };
      
        setFavicon(faviconUrl);
        return () => {
            // Reset the favicon to the default one
            setFavicon(faviconIco);
          };
    }, [])

    return (
        <>
        <div className={classes.header}>
            <Typography className={classes.title} >Landscaping Projects</Typography>
            <Typography className={classes.title2} >Adam</Typography>
            <div className={classes.dropdownContainer}>
                <Tabs 
                className={classes.dropdown}
                value={page}
                centered
                onChange={handlePageChange}
                // MenuProps={{
                //     anchorOrigin: {
                //       vertical: 'bottom',
                //       horizontal: 'left',
                //     },
                //     transformOrigin: {
                //       vertical: 'top',
                //       horizontal: 'left',
                //     },
                //     // getContentAnchorEl: null,
                //   }}
                >
                    <Tab value="quote-requested" label="Quote" sx={{ textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}/>
                    <Tab value="pending-approval" label="Pending" sx={{textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}/>
                    <Tab value="approved" label="Service" sx={{textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}/>
                    <Tab value="completed" label="Complete" sx={{textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}/>
                </Tabs>
            </div>
        </div>
        {page === "quote-requested" && <QuoteRequested></QuoteRequested>}
        {page === "pending-approval" && <PendingCustomerApproval/>}
        {page === "approved" && <Approved/>}
        {page === "completed" && <Completed/>}
        </>
    )
}

export default Landscaper;