import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';

import "./carousel.css";
import { useSlotProps } from "@mui/base";

const useStyles = makeStyles(() => ({
  carousel: {
    overflow: 'hidden',
    textAlign: 'center',
  },
  inner: {
    whiteSpace: 'nowrap',
    transition: 'transform 0.3s',
  },
  
  carouselItem: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    // height: '200px',
    // backgroundColor: 'green',
    color: '#fff',
  },
  
  indicators: {
    display: 'inline',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100vw',
  },
  
//   indicators > button: {
//     margin: 5px,
//   }
  
  active: {
    backgroundColor: 'grey',
    color: '#fff',
    border: '1px',
    width: '15vw',
  },
  inactive: {
    width: '15vw',
    border: 'none',
  },
  thumbnail: {
    width: '15vw',
    height: 'inherit',
    objectFit: 'cover',
  }
}));

export const CarouselItem = ({ children, width }) => {
  const classes = useStyles();
  return (
    <div className={classes.carouselItem} style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children, images }) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (!paused) {
//         updateIndex(activeIndex + 1);
//       }
//     }, 3000);

//     return () => {
//       if (interval) {
//         clearInterval(interval);
//       }
//     };
//   });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });


  return (
    <div
      {...handlers}
      className={classes.carousel}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div className={classes.indicators}>
        {/* <button
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          Prev
        </button> */}
        {React.Children.map(children, (child, index) => {
          return (
            <Button
              style={{minWidth: '30px', padding: '1px'}}
              variant={`${index === activeIndex ? "outlined" : ""}`}
              className={`${index === activeIndex ? classes.active : classes.inactive}`}
              onClick={() => {
                updateIndex(index);
              }}
            >
              {/* {index + 1} */}
              <img className={classes.thumbnail} src={images[index]}/>
            </Button>
          );
        })}
        {/* <button
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          Next
        </button> */}
      </div>
      <div
        className={classes.inner}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
    </div>
  );
};

export default Carousel;
