const DeleteLineItems = async (lineItems, job_id, setLineItems) => {
    let data = {
        lineItems: lineItems,
        job_id: job_id,
    };
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/deleteLineItems`, requestOptions)

    let result = await response.json();
    setLineItems(result);
    return result;
}

export default DeleteLineItems;