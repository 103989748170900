import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Button,
  Radio,
  FormGroup,
  Alert,
  LinearProgress,
} from '@mui/material';
import SubmitPartnerForm from '../API/submit-partner-form';
import translations from '../Translations/partners';

const languages = ['English', 'Spanish', 'Portuguese'];
const experienceOptions = Array.from({ length: 40 }, (_, i) => {
    if (i == 0) {
        return `${i + 1} year`;
    }
    else {
        return `${i + 1} years`;
    }
    
});

const truckOptions = Array.from({ length: 40 }, (_, i) => {
        return `${i + 1}`;
});

function getComponent(place, type) {
    for (var i = 0; i < place.address_components.length; i++) {
      var component = place.address_components[i];
      if (component.types.indexOf(type) !== -1) {
        return component.long_name;
      }
    }
    return '';
}


function Partners() {
  const [language, setLanguage] = useState('English');
  const [hasTruckWithSnowPlow, setHasTruckWithSnowPlow] = useState('');
  const [numTrucks, setNumTrucks] = useState('');
  const [hasSnowBlower, setHasSnowBlower] = useState('');
  const [hasAdditionalPerson, setHasAdditionalPerson] = useState('');
  const [experience, setExperience] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fluentLanguages, setFluentLanguages] = useState([]);
  const [hasSSNOrITINOrEIN, setHasSSNOrITINOrEIN] = useState('');
  const [canProvideW9, setCanProvideW9] = useState('');
  const [hasDriverLicense, setHasDriverLicense] = useState('');
  const [driverLicenseFile, setDriverLicenseFile] = useState(null);
  const [hasInsurance, setHasInsurance] = useState('');
  const [workingWithOtherCompanies, setWorkingWithOtherCompanies] = useState('');
  const [cashApp, setCashApp] = useState(false);
  const [cashAppDetails, setCashAppDetails] = useState('');
  const [venmo, setVenmo] = useState(false);
  const [venmoDetails, setVenmoDetails] = useState('');
  const [zelle, setZelle] = useState(false);
  const [zelleDetails, setZelleDetails] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitText, setSubmitText] = useState('Submit');
  const addressRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(addressRef.current);
    if (hasTruckWithSnowPlow == 'Yes' && addressRef.current) {
      const options = {
        types: ['geocode'], // Restrict the results to geographical locations.
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressRef.current,
        options
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        console.log(place);
        setAddress(`${place.address_components[0].long_name} ${place.address_components[1].long_name}, ${place.address_components[2].long_name}, ${place.address_components[4].short_name}, ${getComponent(place, 'postal_code')}`);
      });
    }
  }, [hasTruckWithSnowPlow]);
//   const [paymentMethod, setPaymentMethod] = useState('');
//   const [paymentMethodDetails, setPaymentMethodDetails] = useState('');

    // React.useEffect(() => {
    //     const textField = addressRef.current;
    
    //     const options = {
    //       types: ['geocode'], // Restrict the results to geographical locations.
    //     };
    //     const autocomplete = new google.maps.places.Autocomplete(textField, options);
    
    //     autocomplete.addListener('place_changed', () => {
    //       const place = autocomplete.getPlace();
    //       setAddress(place.formatted_address);
    //     });
    
    //     // Clean up the event listener when the component is unmounted
    //     return () => {
    //       autocomplete.removeListener('place_changed');
    //     };
    // }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitText != 'Submit') {
        return;
    }
    if (numTrucks == '') {
        setAlert(true);
        setAlertMessage(getText(language, 21))
        return; 
    }
    if (hasSnowBlower == '') {
        setAlert(true);
        setAlertMessage(getText(language, 22))
        return; 
    }
    if (hasAdditionalPerson == '') {
        setAlert(true);
        setAlertMessage(getText(language, 23))
        return;
    }
    if (experience == '') {
        setAlert(true);
        setAlertMessage(getText(language, 24))
        return;
    }
    if (name == '') {
        setAlert(true);
        setAlertMessage(getText(language, 25))
        return;
    }
    if (address == '') {
        setAlert(true);
        setAlertMessage(getText(language, 26))
        return;
    }
    if (email == '') {
        setAlert(true);
        setAlertMessage(getText(language, 27))
        return;
    }
    if (phone == '') {
        setAlert(true);
        setAlertMessage(getText(language, 28))
        return;
    }
    if (fluentLanguages.length == 0) {
        setAlert(true);
        setAlertMessage(getText(language, 29))
        return;
    }
    if (hasSSNOrITINOrEIN == '') {
        setAlert(true);
        setAlertMessage(getText(language, 30))
        return;
    }
    if (canProvideW9 == '') {
        setAlert(true);
        setAlertMessage(getText(language, 31))
        return;
    }
    if (hasDriverLicense == '') {
        setAlert(true);
        setAlertMessage(getText(language, 32))
        return;
    }
    if (hasDriverLicense == 'Yes' && driverLicenseFile == null) {
        setAlert(true);
        setAlertMessage(getText(language, 33))
        return;
    }
    if (hasInsurance == '') {
        setAlert(true);
        setAlertMessage(getText(language, 34))
        return;
    }
    if (workingWithOtherCompanies == '') {
        setAlert(true);
        setAlertMessage(getText(language, 35))
        return;
    }
    if (!cashApp && !venmo && !zelle) {
        setAlert(true);
        setAlertMessage(getText(language, 36))
        return;
    }
    if (cashApp && cashAppDetails == '') {
        setAlert(true);
        setAlertMessage(getText(language, 37))
        return;
    }
    if (venmo && venmoDetails == '') {
        setAlert(true);
        setAlertMessage(getText(language, 38))
        return;
    }
    if (zelle && zelleDetails == '') {
        setAlert(true);
        setAlertMessage(getText(language, 39))
        return;
    }

    setAlert(false);
    var body = {};
    body.numTrucks = numTrucks;
    body.hasSnowBlower = hasSnowBlower;
    body.hasAdditionalPerson = hasAdditionalPerson;
    body.experience = experience;
    body.name = name;
    body.email = email;
    body.address = address;
    body.phone = phone;
    body.fluentLanguages = fluentLanguages;
    body.hasSSNOrITINOrEIN = hasSSNOrITINOrEIN;
    body.canProvideW9 = canProvideW9;
    body.hasDriverLicense = hasDriverLicense;
    body.hasInsurance = hasInsurance;
    body.workingWithOtherCompanies = workingWithOtherCompanies;
    body.cashAppDetails = cashAppDetails;
    body.venmoDetails = venmoDetails;
    body.zelleDetails = zelleDetails;

    // send body to backend API
    setSubmitting(true);
    setSubmitText('Submitting...');
    SubmitPartnerForm(body, driverLicenseFile, handleDoneSubmitting);
  };

  const handleDoneSubmitting = () => {
    setSubmitting(false);
    setSubmitText('Submitted');
    navigate('/submissionsuccessful');
  }

  const handlePhoneChange = (input) => {
    const cleaned = input.replace(/\D/g, '');
    let formatted;
    // If the input is not empty, format the phone number
    if (cleaned.length > 0) {
        if (cleaned.length < 4) {
        formatted = `(${cleaned}`;
        } else if (cleaned.length < 7) {
        formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
        } else {
        formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
        }
    } else {
        formatted = '';
    }

    setPhone(formatted);
  }

  const handlePlaceSelect = (place) => {
    setAddress(place.formatted_address);
  };

  const getText = (language, index) => {
    if (language == 'English') {
        return translations.partners[index].english;
    }
    else if (language == 'Spanish') {
        return translations.partners[index].spanish;
    }
    else {
        return translations.partners[index].portoguese;
    }
  }


  return (
    <form onSubmit={handleSubmit} style={{padding: '50px'}}>
        <h1>{getText(language, 0)}</h1>
          <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">{getText(language, 1)}</FormLabel>
          <RadioGroup
            row={false}
            aria-label="Language"
            name="language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {languages.map((lang) => (
              <FormControlLabel key={lang} value={lang} control={<Radio />} label={lang} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
        <>
          <div style={{paddingTop: '20px'}}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{getText(language, 2)}</FormLabel>
              <RadioGroup
                row
                aria-label="Has truck with snow plow"
                name="hasTruckWithSnowPlow"
                value={hasTruckWithSnowPlow}
                onChange={(e) => setHasTruckWithSnowPlow(e.target.value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>

          {hasTruckWithSnowPlow === 'No' && (
            <>
            <h1>{getText(language, 3)}</h1> 
            </>
          )}

          {hasTruckWithSnowPlow !== '' && hasTruckWithSnowPlow !== 'No' && (
            <>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 4)}</FormLabel>
                <Select
                  value={numTrucks}
                  onChange={(e) => setNumTrucks(e.target.value)}
                  displayEmpty
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem value="" disabled>
                  {getText(language, 5)}
                  </MenuItem>
                  {truckOptions.map((exp) => (
                    <MenuItem key={exp} value={exp}>{exp}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 6)}</FormLabel>
                <RadioGroup
                  row
                  aria-label="Has snowblower"
                  name="hasSnowBlower"
                  value={hasSnowBlower}
                  onChange={(e) => setHasSnowBlower(e.target.value)}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 7)}</FormLabel>
                <RadioGroup
                  row
                  aria-label="Has additional person"
                  name="hasAdditionalPerson"
                  value={hasAdditionalPerson}
                  onChange={(e) => setHasAdditionalPerson(e.target.value)}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 8)}</FormLabel>
                <Select
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  displayEmpty
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem value="" disabled>
                  {getText(language, 9)}
                  </MenuItem>
                  {experienceOptions.map((exp) => (
                    <MenuItem key={exp} value={exp}>{exp}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
            <FormLabel component="legend">{getText(language, 10)}</FormLabel>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{paddingTop: '20px'}}>
              {/* Address - Placeholder for Google Maps integration */}
              <FormLabel component="legend">{getText(language, 11)}</FormLabel>
              <TextField
                inputRef={addressRef}
                label="Address"
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                />
            </div>
            <div style={{paddingTop: '20px'}}>
                <FormLabel component="legend">{getText(language, 12)}</FormLabel>
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{paddingTop: '20px'}}>
                <FormLabel component="legend">{getText(language, 13)}</FormLabel>
              <TextField
                label="Phone"
                value={phone}
                onChange={(e) => handlePhoneChange(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 14)}</FormLabel>
                {/* <Checkbox
                  checked={fluentLanguages.includes('English')}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFluentLanguages((prevLanguages) => {
                      if (isChecked) return [...prevLanguages, 'English'];
                      return prevLanguages.filter((lang) => lang !== 'English');
                    });
                  }}
                  label="English"
                />
                <Checkbox
                  checked={fluentLanguages.includes('Espanol')}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFluentLanguages((prevLanguages) => {
                      if (isChecked) return [...prevLanguages, 'Espanol'];
                      return prevLanguages.filter((lang) => lang !== 'Espanol');
                    });
                  }}
                  label="Espanol"
                />
                <Checkbox
                  checked={fluentLanguages.includes('Portuguese')}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFluentLanguages((prevLanguages) => {
                      if (isChecked) return [...prevLanguages, 'Portuguese'];
                      return prevLanguages.filter((lang) => lang !== 'Portuguese');
                    });
                  }}
                  label="Portuguese"
                /> */}
                <FormGroup>
            {languages.map((lang) => (
              <FormControlLabel
                key={lang}
                control={<Checkbox />}
                label={lang}
                checked={fluentLanguages.includes(lang)}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setFluentLanguages((prevLanguages) => {
                    if (isChecked) return [...prevLanguages, lang];
                    return prevLanguages.filter((l) => l !== lang);
                  });
                }}
              />
            ))}
          </FormGroup>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 15)}</FormLabel>
                <RadioGroup
                  row
                  aria-label="Has SSN/ITIN/EIN"
                  name="hasSSNOrITINOrEIN"
                  value={hasSSNOrITINOrEIN}
                  onChange={(e) => setHasSSNOrITINOrEIN(e.target.value)}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 16)}</FormLabel>
                <RadioGroup
                  row
                  aria-label="Can provide W-9"
                  name="canProvideW9"
                  value={canProvideW9}
                  onChange={(e) => setCanProvideW9(e.target.value)}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 17)}</FormLabel>
                <RadioGroup
                  row
                  aria-label="Has driver license"
                  name="hasDriverLicense"
                  value={hasDriverLicense}
                  onChange={(e) => setHasDriverLicense(e.target.value)}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
                {hasDriverLicense === 'Yes' && (
                  <TextField
                    autoFocus
                    label="Upload driver's license"
                    type="file"
                    onChange={(e) => setDriverLicenseFile(e.target.files[0])}
                  />
                )}
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 18)}</FormLabel>
                <RadioGroup
                  row
                  aria-label="Has insurance"
                  name="hasInsurance"
                  value={hasInsurance}
                  onChange={(e) => setHasInsurance(e.target.value)}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 19)}</FormLabel>
                <RadioGroup
                  row
                  aria-label="Working with other companies"
                  name="workingWithOtherCompanies"
                  value={workingWithOtherCompanies}
                  onChange={(e) => setWorkingWithOtherCompanies(e.target.value)}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{paddingTop: '20px'}}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{getText(language, 20)}</FormLabel>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Cash App"
                  onChange={(e) => {
                    if (e.target.checked) setCashApp(true);
                    else {
                        setCashApp(false); 
                    }
                  }}
                />
                {cashApp && (
                  <TextField
                    label="Cash App details"
                    value={cashAppDetails}
                    onChange={(e) => {
                        setCashAppDetails(e.target.value)
                    }}
                  />
                )}
                <FormControlLabel
                  control={<Checkbox />}
                  label="Venmo"
                  onChange={(e) => {
                    if (e.target.checked) setVenmo(true);
                    else {
                        setVenmo(false);
                    }
                  }}
                />
                {venmo && (
                  <TextField
                    label="Venmo details"
                    value={venmoDetails}
                    onChange={(e) => {setVenmoDetails(e.target.value)}}
                  />
                )}
                <FormControlLabel
                  control={<Checkbox />}
                  label="Zelle"
                  onChange={(e) => {
                    if (e.target.checked) setZelle(true);
                    else {
                        setZelle(false);
                    }
                  }}
                />
                {zelle && (
                  <TextField
                    label="Zelle details"
                    value={zelleDetails}
                    onChange={(e) => setZelleDetails(e.target.value)}
                  />
                )}
              </FormControl>
            </div>
            </>
          )}
        </>

      {/* Submit button */}
      {(zelle || cashApp || venmo) && hasTruckWithSnowPlow === 'Yes' && (
        <div style={{paddingTop: '20px'}}>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            {submitText}
          </Button>
        </div>
      )}
      {submitting && <LinearProgress></LinearProgress>}
      {alert && <Alert severity="error">{alertMessage}</Alert>}
    </form>
  );
};

export default Partners;