
const GetSurchargeJobs = async (setJobs) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/surcharge`, requestOptions)

    let result = await response.json();
    setJobs(result);
    return result;
}

export default GetSurchargeJobs;