import { Upload } from 'upload-js'

const upload = Upload({
  apiKey: "public_W142hVXxg6aWu6EYP4dUV6ASPJNX"
})

async function uploadLandscapingImage(img, setProgress, index, image_upload_path) {
  
      const { fileUrl, filePath } = await upload.uploadFile(
        // Required.
        img,
    
      //   // Optional.
        {
      //     onBegin: ({ cancel }) => {
      //       // Call 'cancel()' to stop the upload.
      //     },
          onProgress: ({ bytesSent, bytesTotal }) => {
            setProgress((bytesSent/bytesTotal)*100, index);
          },
      //     metadata: { 
      //       // Up to 2KB of arbitrary JSON.
      //       productId: 60891 
      //     },
      //     tags: [
      //       // Up to 25 tags per file.
      //       "product_image"
      //     ],
          path: {
            // See "custom file paths" documentation below.
            folderPath: `${image_upload_path}`,
            fileName: "{UTC_DAY}_{UTC_MONTH}_{UTC_YEAR}_{UTC_HOUR}_{UTC_MINUTE}_{UNIQUE_DIGITS_8}{ORIGINAL_FILE_EXT}"
          }
        }
      );
}

export default uploadLandscapingImage;