const ArchiveJob = async (job_id, handleDone) => {
    let data = {
        job_id: job_id, 
    };
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/archive`, requestOptions)

    handleDone();
    return result;
}

export default ArchiveJob;