const NewJob = async (customer_id, project, handleDone) => {
    let data = {
        customer_id: customer_id,
        project: project, 
    };
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/newJob`, requestOptions)

    if (response.ok) {
        handleDone();
    }
    return;
}

export default NewJob;