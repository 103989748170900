
const GetApprovedJobs = async (setQuotes) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/approved`, requestOptions)

    let result = await response.json();
    setQuotes(result);
    return result;
}

export default GetApprovedJobs;