import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { Typography, Select, MenuItem, Divider, Button } from '@material-ui/core';
import QuoteRequestedAdmin from '../Bodies/admin/quote-requested';
import NeedsReviewAdmin from '../Bodies/admin/needs-review';
import PendingCustomerApprovalAdmin from '../Bodies/admin/pending-customer-approval';
import ApprovedAdmin from '../Bodies/admin/approved';
import IncomeAdmin from '../Bodies/admin/income';
import CompletedAdmin from '../Bodies/admin/completed';
import faviconUrl from '../../assets/weberwelt_logo.ico';
import faviconIco from '../../assets/favicon.ico';
import Weber from '../API/weber';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const useStyles = makeStyles(() => ({ 
    header: {
        backgroundColor: '#7626A8',
        paddingTop: '10px',
    },
    title: {
        marginLeft: '20px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '8vw',
        color: 'white',
    },
    title2: {
        marginLeft: '20px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '6vw',
        color: 'white',
    },
    dropdownContainer: { 
        textAlign: 'center',
    },
    dropdown: {
        marginTop: '10px',
        marginBottom: '20px',
        paddingTop: '8px', 
        paddingBottom: '8px', 
        paddingLeft: '20px',
        paddingRight: '20px',
        fontFamily: 'Poppins',
        fontSize: '5vw',
        backgroundColor: '#A9A9A9',
        // borderRadius: '6px',
        position: 'relative',
        '&:before': {
            borderBottom: 'none', // Remove the underline
          },
          '&:after': {
            borderBottom: 'none', // Remove the underline when the select is focused
          },
          '& .MuiSelect-select': {
            paddingRight: '0', // Remove the space for the arrow
            '&:focus': {
              backgroundColor: 'transparent', // Remove the background color when focused
            },
          },
          '& .MuiSelect-icon': {
            // display: 'none', // Hide the dropdown arrow
          },
    },
    tableHeader: {
        marginTop: '15px',
        marginBottom: '5px'
    },
    tableColumnHeader: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '5vw',
        lineHeight: '30px',
        /* identical to box height */
    },
    mobileScreen: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      },
      passcodeContainer: {
        marginBottom: '10px',
        height: '50vh',
        width: '100vw',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      passcodeLine: {
        position: 'absolute',
        bottom: '10px',
        // width: '100vw',
        
      },
      passcodeDigit: {
        marginRight: '5px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
      },
      numberButtonsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        justifyContent: 'center',
        gridGap: '5px',
        
      },
      numberButtons: {
        width: '25vw',
        height: '20vw',
        backgroundColor: '#7626A8',
        fontSize: '5vw',
        fontWeight: '600',
        '&:focus': {
            backgroundColor: '#7626A8',
        },
        '&.Mui-focus': {
            backgroundColor: '#7626A8',
        }
      },
      noButton: {
        width: '25vw',
        height: '20vw',
        opacity: 0.0,
        pointerEvents: 'none',
      }
}))


function LandscaperAdmin() {
    const classes = useStyles();
    const [page, setPage] = React.useState("quote-requested");
    const [signedIn, setSignedIn] = React.useState(false);
    const [passcode, setPasscode] = useState(['', '', '', '', '', '']);

    const handlePageChange = (event, newValue) => {
      setPage(newValue);
  }

    React.useEffect(() => {
        document.title = "Landscaper - Admin";
        const setFavicon = (faviconUrl) => {
            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
              favicon.href = faviconUrl;
            }
          };
      
        setFavicon(faviconUrl);
        return () => {
            // Reset the favicon to the default one
            setFavicon(faviconIco);
          };
    }, [])

    const handleDigitInput = (digit) => {
        const updatedPasscode = [...passcode];
        const nextEmptyIndex = updatedPasscode.findIndex((value) => value === '');
    
        if (nextEmptyIndex !== -1) {
          updatedPasscode[nextEmptyIndex] = `${digit}`;
          setPasscode(updatedPasscode);
        }

        const nextEmpty = updatedPasscode.findIndex((value) => value === '');

        if (nextEmpty === -1) {
            Weber(updatedPasscode.join(''), handleSubmitDone);
        }
      };

    const handleSubmitDone = (result) => {
        if (result && result.succeeded && result.succeeded === true) {
            setSignedIn(true);
        }
        else {
            setPasscode(['', '', '', '', '', '']);
        }
    }

    return (
        <>
        {!signedIn &&<>
            <div className={classes.mobileScreen}>
      <div className={classes.passcodeContainer}>
      <div className={classes.passcodeLine}>
        {passcode.map((value, index) => (
          <span key={index} className={classes.passcodeDigit}>
            {value || '-'}
          </span>
        ))}
        </div>
      </div>
      <div className={classes.numberButtonsContainer}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, '#', 0, '*'].map((digit) => (
          <Button
            key={digit}
            variant="contained"
            color="primary"
            onClick={() => handleDigitInput(digit)}
            className={digit === -1 ? classes.noButton : classes.numberButtons}
          >
            {digit}
          </Button>
        ))}
      </div>
    </div>
        </>}
        {signedIn && <>
        <div className={classes.header}>
            <Typography className={classes.title} >Landscaping Projects</Typography>
            <Typography className={classes.title2} >Admin</Typography>
            <div className={classes.dropdownContainer}>
            <Tabs 
                className={classes.dropdown}
                value={page}
                centered
                variant="scrollable"
                scrollButtons="auto"
                onChange={handlePageChange}
                // MenuProps={{
                //     anchorOrigin: {
                //       vertical: 'bottom',
                //       horizontal: 'left',
                //     },
                //     transformOrigin: {
                //       vertical: 'top',
                //       horizontal: 'left',
                //     },
                //     // getContentAnchorEl: null,
                //   }}
                >
                    <Tab value="quote-requested" label="Quote" sx={{ textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}></Tab>
                    <Tab value="needs-review" label="Review" sx={{ textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}></Tab>
                    <Tab value="pending-approval" label="Pending" sx={{ textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}></Tab>
                    <Tab value="approved" label="Service" sx={{ textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}></Tab>
                    <Tab value="completed" label="Completed" sx={{ textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}></Tab>
                    <Tab value="billing" label="Billing" sx={{ textTransform: 'capitalize', fontWeight: '500', '&.Mui-selected': { color: 'white' }}}></Tab>
                </Tabs>
            </div>
        </div>
        {page === "quote-requested" && <QuoteRequestedAdmin></QuoteRequestedAdmin>}
        {page === "needs-review" && <NeedsReviewAdmin></NeedsReviewAdmin>}
        {page === "pending-approval" && <PendingCustomerApprovalAdmin/>}
        {page === "approved" && <ApprovedAdmin/>}
        {page === "completed" && <CompletedAdmin/>}
        {page === "billing" && <IncomeAdmin/>}
        </>}
        </>
    )
}

export default LandscaperAdmin;