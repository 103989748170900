import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { Typography, Select, MenuItem, Divider, Box } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import GetQuotes from '../API/get-quotes';
import GetSurchargeAndPendingApprovalJobs from '../API/get-surcharge-and-pending-approval';
import {QuoteRequestedRow} from './quote-requested';

const useStyles = makeStyles(() => ({ 
    tableHeader: {
        marginTop: '15px',
        marginBottom: '5px'
    },
    tableColumnHeader: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '5vw',
        lineHeight: '30px',
        /* identical to box height */
    },
    header: {
        backgroundColor: '#D9D9D9',
    },
    rowText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '3vw',
        lineHeight: '22px',
    },
    firstLine: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
        marginRight: '20px',
        paddingTop: '10px',
    },
    exit: {
        marginLeft: 'auto',
    },
    name: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '32px',
    },
    body: {
        textAlign: 'center',
    },
    statusText: {
        color: '#6D6D6D',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '30px',
    },
    callContainer: {
        marginRight: '20px',
        marginLeft: '20px',
        marginBottom: '20px',
        backgroundColor: '#D9D9D9',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    quoteBody: {
        textAlign: 'left',
        marginLeft: '20px',
        marginRight: '20px',
    },
    sectionText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
    },
    descriptionContainer: {
        backgroundColor: '#D9D9D9',
        padding: '10px',
        marginBottom: '20px',
        overflowWrap: 'auto'
    },
    text: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px', 
    },
    lineItemContainer: {
        display: 'flex',
        alignItems: 'center',
        // marginTop: '10px',
        marginBottom: '10px',
    },
    totalContainer: {
        display: 'flex',
        width: '30vw',
        paddingTop: '3px',
        marginLeft: 'auto',
    },
    addLineItem: {
        display: 'flex',
        alignItems: 'center',
        // marginLeft: '25%',
        margin: 'auto',
    },
    addLineContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#D9D9D9',
        borderRadius: '5px',
        width: '50vw',
        marginLeft: '25%',
        marginTop: '30px',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    textfield: {
        width: '90vw',
        paddingBottom: '20px',
    },
    rightAlign: {
        '& .MuiInput-root': {
          textAlign: 'right',
        },
      },
    actionContainer: {
        position: 'absolute',
        bottom: '20px',
        width: '100%',
    }
}))

function PendingCustomerApproval(props) {
    const classes = useStyles();
    const [jobs, setJobs] = React.useState([])

    React.useEffect(() => {
        GetSurchargeAndPendingApprovalJobs(setJobs);
    }, [])

    return (
        <>
        <div className={classes.tableHeader}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography 
                    style={{marginLeft: '20px'}}
                    className={classes.tableColumnHeader}
                    >Address</Typography>
                </Grid>
                <Grid item xs={7} style={{textAlign: 'center'}}>
                    <Typography 
                    className={classes.tableColumnHeader} 
                    >Days Since Request</Typography>
                </Grid>
            </Grid>
        </div>
        <Divider style={{height: '1px', backgroundColor: 'black', marginBottom: '20px'}}></Divider>
        {jobs.map((q) => {
            return (
                <QuoteRequestedRow q={q} setQuotes={setJobs}></QuoteRequestedRow>
            )
        })}
        </>
    )

}

export default PendingCustomerApproval;