import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { Typography, Select, MenuItem, Divider, Box, CircularProgress, LinearProgress, IconButton} from '@material-ui/core';
import Grid from '@mui/material/Grid';
import GetQuotes from '../API/get-quotes';
import GetSurchargeAndPendingApprovalJobs from '../API/get-surcharge-and-pending-approval';
import Drawer from '@mui/material/Drawer';
import Arrow from '../../assets/arrow.png';
import Phone from '../../assets/phone.png';
import Plus from '../../assets/plus.png';
import Menu from '../../assets/menu.png';
import Duplicate from '../../assets/duplicate.png';
import Cross from '../../assets/cross.png';
import Trash from '../../assets/trash.png';
import Checkbox from '../../assets/checkbox.png';
import TextField from '@mui/material/TextField';
import CreateLineItem from '../API/create-line-item';
import DeleteLineItems from '../API/delete-line-items';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ApplyServiceRangeAndUpdateStatus from '../API/apply-service-range-and-update-status';
import UpdateServiceRange from '../API/update-service-range';
import PictureSection from '../Bodies/picture-section';
import MarkJobComplete from '../API/mark-job-complete';
import GetLineItems from '../API/get-line-items';
import NewJob from '../API/new-job';

const useStyles = makeStyles(() => ({ 
    tableHeader: {
        marginTop: '15px',
        marginBottom: '5px'
    },
    tableColumnHeader: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '5vw',
        lineHeight: '30px',
        /* identical to box height */
    },
    header: {
        backgroundColor: '#D9D9D9',
    },
    rowText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '3vw',
        lineHeight: '22px',
    },
    firstLine: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
        marginRight: '20px',
        paddingTop: '10px',
    },
    exit: {
        marginLeft: 'auto',
    },
    name: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '32px',
    },
    body: {
        textAlign: 'center',
    },
    statusText: {
        color: '#6D6D6D',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '30px',
    },
    callContainer: {
        marginRight: '20px',
        marginLeft: '20px',
        marginBottom: '20px',
        backgroundColor: '#D9D9D9',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    quoteBody: {
        textAlign: 'left',
        marginLeft: '20px',
        marginRight: '20px',
    },
    sectionText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
    },
    descriptionContainer: {
        backgroundColor: '#D9D9D9',
        padding: '10px',
        marginBottom: '20px',
        overflowWrap: 'auto'
    },
    text: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px', 
    },
    lineItemContainer: {
        display: 'flex',
        alignItems: 'center',
        // marginTop: '10px',
        marginBottom: '10px',
    },
    totalContainer: {
        display: 'flex',
        width: '30vw',
        paddingTop: '3px',
        marginLeft: 'auto',
    },
    addLineItem: {
        display: 'flex',
        alignItems: 'center',
        // marginLeft: '25%',
        margin: 'auto',
    },
    addLineContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#D9D9D9',
        borderRadius: '5px',
        width: '50vw',
        marginLeft: '25%',
        marginTop: '30px',
        paddingTop: '10px',
        paddingBottom: '10px',
        marginBottom: '20px',
    },
    textfield: {
        width: '90vw',
        paddingBottom: '20px',
    },
    rightAlign: {
        '& .MuiInput-root': {
          textAlign: 'right',
        },
      },
    actionContainer: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        // backgroundColor: 'rgba(0, 0, 0, 0.4)',
        backgroundColor: 'rgba(255, 255, 255, 1.0)',
        paddingTop: '20px',
        paddingBottom: '20px',
        boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)',
        // opacity: '0.7',
    },
    actionContainerBackground: {
        // position: 'absolute',
        // bottom: '0',
        // top: '20vh',
        // color: 'black',
        // width: '100vw'
    }
}))

const ModalStyle = {
    display: 'block',
    width: '100vw',
    height: '100%',
    bgcolor: 'background.paper',
    // borderRadius: '10px',
    boxShadow: 24,
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const NewJobStyle = {
    display: 'block',
    width: '100vw',
    height: '40vh',
    bgcolor: '#D9D9D9',
    // borderRadius: '10px',
    boxShadow: 24,
    overflowY: 'auto',
    overflowX: 'hidden',
}


const AddLineItemStyle = {
display: 'block',
width: '100vw',
height: '50vh',
bgcolor: '#EFEFEF',
// borderRadius: '10px',
boxShadow: 24,
overflowY: 'auto',
overflowX: 'hidden',
};

const MenuStyle = {
    display: 'block',
    width: '100vw',
    height: '20vh',
    bgcolor: '#D9D9D9',
    // borderRadius: '10px',
    boxShadow: 24,
    overflowY: 'auto',
    overflowX: 'hidden',
    };



function AddLineItem(props) {
    const [open, setOpen] = React.useState(false);
    const [description, setDescription] = React.useState("");
    const [amount, setAmount] = React.useState();
    const [uploading, setUploading] = React.useState(false);
    const classes = useStyles();    

    const handleInputChange = (event) => {
        // Remove any non-numeric characters from the input value
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        setAmount(event.target.value);
      };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const validData = () => {
        return description != "" && amount != undefined && amount > 0;
    }

    const submitLineItem = () => {
        setUploading(true);
        CreateLineItem(props.q.id, description, amount, handleSubmitDone);
    }

    const handleSubmitDone = (lineItems) => {
        props.setLineItems(lineItems);
        setUploading(false);
        props.notifyChange();
        setOpen(false);
        setDescription("");
        setAmount(0);
    }

    const addNewLineItem = () => (
        <Box
        sx={{...AddLineItemStyle}}>
            <div className={classes.firstLine} style={{paddingBottom: '20px'}}>
                <Typography className={classes.sectionText}>New Line Item</Typography>
                <img className={classes.exit} src={Cross} onClick={()=> setOpen(false)}></img>
            </div>
            <Box className={classes.quoteBody}>
                <Typography className={classes.text}>Description:</Typography>
                <TextField 
                sx={{width: 'calc(100vw - 40px)', height: '60px', marginBottom: '20px'}}
                className={classes.textfield} 
                id="outlined-basic" label="" 
                value={description}
                variant="outlined" 
                onChange={handleDescriptionChange}
                multiline/>
                <Typography className={classes.text}>Amount:</Typography>
                <TextField
                    // label="Amount"
                    value={amount}
                    variant="outlined"
                    sx={{width: '100%'}}
                    InputProps={{
                        startAdornment: "$",
                    }}
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                    }}
                    onChange={handleInputChange}
                    />
                {validData() && <Box 
                className={classes.addLineContainer}
                style={{backgroundColor: '#D9D9D9'}}
                >
                    { uploading ? (<Box sx={{ width: '100%' }}>
                        <LinearProgress />
                        </Box>): (<Box className={classes.addLineItem} onClick={submitLineItem}>
                        <img src={Plus}></img>
                        <Typography className={classes.text} style={{marginTop: 'auto', marginRight: '10px'}}>Line Item</Typography>
                    </Box>)}
                </Box>}
            </Box>
        </Box>
    );

    const handleClose = () => {
        setOpen(false);
      };
    
    return (
        <>
        <Box 
        className={classes.addLineContainer}
        onClick={() => setOpen(true)}
        >
            <Box className={classes.addLineItem}>
                <img src={Plus}></img>
                <Typography className={classes.text} style={{marginTop: 'auto', marginRight: '10px'}}>Line Item</Typography>
            </Box>
        </Box>
        <Drawer
        anchor={'bottom'}
        open={open}
        onClose={handleClose}
    >
    {addNewLineItem()}
  </Drawer>
  </>
    )
}

function LineItemRow(props) {
    const classes = useStyles();

    const isSelected = (id) => {
        if (props.selected[id] != undefined && props.selected[id]) {
            return true;
        }
        return false;
    }

    return (
        <div className={classes.lineItemContainer} onClick={() => props.select(props.l.id)} style={{color: isSelected(props.l.id) ? '#737373': 'black'}}>
            {isSelected(props.l.id) && <img src={Checkbox} style={{marginRight: '5px'}}></img>}
            <Typography className={classes.text}>{props.l.description}</Typography>
            <Typography className={classes.text} style={{marginLeft: 'auto'}}>${props.l.price}</Typography>
        </div>
    )
}



export function QuoteRequestedRow(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [lineItems, setLineItems] = React.useState([]);
    const [selected, setSelected] = React.useState({});
    const [shouldNotifyForApproval, setShouldNotifyForApproval] = React.useState(false);
    // const [serviceDate, setServiceDate] = React.useState(dayjs(props.q.service_date));
    const [serviceRange, setServiceRange] = React.useState(props.q.service_range);
    const [newJob, setNewJob] = React.useState(false);
    const [projectDetails, setProjectDetails] = React.useState("");
    const [creatingNewJob, setCreatingNewJob] = React.useState(false);
    const [newJobCreated, setNewJobCreated] = React.useState(false);

    React.useEffect(() => {
        GetLineItems(props.q.id, setLineItems);
        // setLineItems(props.q.LineItems)
    }, props.q)

    const handleCallClick = () => {
        window.location.href = `tel:${props.q.Customer.phone}`;
    } 

    const totalPrice = () => {
        let price = 0;
        for (let i = 0; i < lineItems.length; i++) {
            price += lineItems[i].price;
        }
        return price;
    }

    const select = (id) => {
        if (isSelected(id)) {
            selected[id] = false;
        }
        else {
            selected[id] = true;
        }
        let newSelected = {...selected}
        setSelected(newSelected);
    }

    const isSelected = (id) => {
        if (selected[id] != undefined && selected[id]) {
            return true;
        }
        return false;
    }

    const somethingSelected = () => {
        for (const key in selected) {
            if (selected[key]) {
                return true;
            }   
        }
        return false;
    }

    const deleteSelected = () => {
        var lineItems = []
        for (const key in selected) {
            if (selected[key]) {
                lineItems.push(key);
            }   
        }
        DeleteLineItems(lineItems, props.q.id, setLineItems);
        setSelected({});
    }

    const handleServiceRangeChange = (event) => {
        UpdateServiceRange(props.q.id, event.target.value) // TODO: Update
        setServiceRange(event.target.value);
    }

    const submit = () => {
        if (props.q.status == 'quote') {
            ApplyServiceRangeAndUpdateStatus(props.q.id, serviceRange, handleSubmitDone); // TODO: Update
        }
        else if (props.q.status == 'surcharge') {
            ApplyServiceRangeAndUpdateStatus(props.q.id, serviceRange, handleSubmitDone); // TODO: Update
        }
        else if (props.q.status == 'pending_approval') {
            ApplyServiceRangeAndUpdateStatus(props.q.id, serviceRange, handleSubmitDone); // TODO: Update
        }
        else if (props.q.status == 'approved') {
            if (shouldNotifyForApproval) {
                ApplyServiceRangeAndUpdateStatus(props.q.id, serviceRange, handleSubmitDone); // TODO: Update
            }
            else {
                MarkJobComplete(props.q.id, handleSubmitDone);
            }
        }
        setShouldNotifyForApproval(false);
    }

    const handleSubmitDone = (jobs) => {
        props.setQuotes(jobs);
        setOpen(false);
    }

    const getTextForStatus = (status) => {
        if (status == "quote") {
            return "Quote";
        }
        else if (status == "surcharge") {
            return "Pending";
        }
        else if (status == "pending_approval") {
            return "Pending";
        }
        else if (status == 'approved') {
            return "Service";
        }
        else if (status == 'complete') {
            return "Complete";
        }
    }

    const getSubmitText = (status) => {
        if (status == "quote") {
            return "Submit for approval";
        }
        else if (status == 'approved') {
            if (shouldNotifyForApproval) {
                return "Re-submit for approval";
            }
            return "Complete";
        }
        else {
            return "Re-submit for approval";
        }
    }

    const shouldNotifyDateChange = () => {
        if (props.q.status != "quote" && serviceRange != props.q.service_range && !readyForApproval()) {
            return true;
        }
        return false;
    }

    const readyForApproval = () => {
        if (lineItems.length > 0 && serviceRange != null && serviceRange != undefined && serviceRange != "") {
            if (props.q.status == "quote") {
                return true;
            }
            else if (props.q.status == 'approved') {
                return true;
            }
            else {
                return shouldNotifyForApproval;
            }
        }
        // if (props.q.status == 'approved') {
        //     return true;
        // }
        return false;
    }

    const notifyChange = () => {
        setShouldNotifyForApproval(true);
    }

    const showBeforePictureSection = () => {
        return true;
    }

    const showAfterPictureSection = () => {
        if (props.q.status != 'quote' && props.q.status != 'surcharge' && props.q.status != 'pending_approval') {
            return true;
        }
        else {
            return false;
        }
    }

    const handleClose = () => {
        props.updateData();
    }

    const initiateNewJob = () => {
        setNewJob(true);
    }

    const handleResendEmailComplete = () => {
        setSendingEmail(false);
    }

    const createNewJob = () => {
        console.log(props.q);
        console.log(projectDetails);
        setCreatingNewJob(true);
        NewJob(props.q.Customer.id, projectDetails, handleNewJobDone);
    }

    const handleNewJobDone = () => {
        setCreatingNewJob(false);
        setNewJobCreated(true);
    }

    const validData = () => {
        if (projectDetails != undefined && projectDetails != "") {
            return true;
        }
        return false;
    }

    const handleDescriptionChange = (event) => {
        setProjectDetails(event.target.value);
    }

    const closeNewJob = () => {
        setNewJob(false);
        setCreatingNewJob(false);
        setNewJobCreated(false);
    }

    const newJobModal = () => (
        <Box
        sx={{...NewJobStyle}}>
            <div className={classes.firstLine} style={{paddingBottom: '20px'}}>
                <Typography className={classes.sectionText}>Create a new job for {props.q.Customer.firstname}</Typography>
                <img className={classes.exit} src={Cross} onClick={closeNewJob}></img>
            </div>
            <Box className={classes.quoteBody}>
                <Typography className={classes.text}>Description:</Typography>
                <TextField 
                sx={{width: 'calc(100vw - 40px)', marginBottom: '20px'}}
                className={classes.textfield} 
                id="outlined-basic" label="" 
                value={projectDetails}
                variant="outlined" 
                onChange={handleDescriptionChange}
                multiline/>
                {validData() && <Box 
                className={classes.addLineContainer}
                style={{backgroundColor: '#E9E9E9'}}
                >
                    {!newJobCreated ? (creatingNewJob ? (<Box className={classes.addLineItem} sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>):
                    (<Box className={classes.addLineItem} onClick={createNewJob}>
                        {/* <img src={Plus}></img> */}
                        <Typography className={classes.text} style={{marginTop: 'auto', marginRight: '10px'}}>Create New Job</Typography>
                    </Box>)):(<Box className={classes.addLineItem}>
                        {/* <img src={Plus}></img> */}
                        <Typography className={classes.text} style={{marginTop: 'auto', marginRight: '10px'}}> New Job Created!</Typography>
                    </Box>)}
                </Box>}
            </Box>
        </Box>
    )

    const modalMenu = () => (
        <Box
        sx={{...MenuStyle}}>
            <div className={classes.firstLine} style={{paddingBottom: '20px'}}>
                <Typography className={classes.sectionText}>Actions</Typography>
                <img className={classes.exit} src={Cross} onClick={()=> setMenuOpen(false)}></img>
            </div>
            <Box className={classes.quoteBody} style={{display: 'flex', justifyContent: 'space-around'}}>
            {/* <Box style={{verticalAlign: 'middle', margin: 'auto'}}>
            <IconButton aria-label="delete" onClick={archiveJob}>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <img src={Archive} style={{width: '30px', height: '30px'}}></img>
                    </Grid>
                    <Grid item>
                    <Typography>Archive</Typography>
                    </Grid>
                </Grid>
            </IconButton>
            </Box>
            <Box style={{verticalAlign: 'middle', margin: 'auto'}}>
            <IconButton aria-label="delete" onClick={initiateResendEmail}>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                    <img src={Resend} style={{width: '30px', height: '30px'}}></img>
                    </Grid>
                    <Grid item>
                    <Typography>Email</Typography>
                    </Grid>
                </Grid>
            </IconButton>
            </Box> */}
            <Box style={{verticalAlign: 'middle', margin: 'auto'}}>
            <IconButton aria-label="delete" onClick={initiateNewJob}>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                    <img src={Duplicate} style={{width: '30px', height: '30px'}}></img>
                    </Grid>
                    <Grid item>
                        <Typography>New Job</Typography>
                    </Grid>
                </Grid>
            </IconButton>
            </Box>
            </Box>
        </Box>
    )



    const quoteDetails = () => (
        <Box
          sx={{ ...ModalStyle}}
        //   onClick={() => setOpen(false)}
        //   onKeyDown={() => setOpen(false)}
        >
          <Box className={classes.header}>
            <div className={classes.firstLine}>
                <Typography className={classes.name}>{props.q.Customer.firstname}</Typography>
                <img className={classes.exit} src={Arrow} onClick={()=> setOpen(false)}></img>
            </div>
            <div style={{marginLeft: '20px', marginRight: '20px', paddingBottom: '10px', display: 'flex', alignItems: 'center',}}>
                    <Typography className={classes.rowText}>{props.q.Customer.address}, {props.q.Customer.city}, MA, {props.q.Customer.zipcode}</Typography>
                    <img className={classes.exit} src={Menu} onClick={()=> setMenuOpen(true)}></img>
            </div>
          </Box>
          <Box className={classes.body}>
            <Typography className={classes.statusText}>{getTextForStatus(props.q.status)}</Typography>
            <Box className={classes.callContainer} onClick={handleCallClick}>
                <img src={Phone}></img>
            </Box>
            <Box className={classes.quoteBody}>
                <Typography className={classes.sectionText}>Customer Request:</Typography>
                <Box className={classes.descriptionContainer}>
                    <Typography className={classes.text}>{props.q.description}</Typography>
                </Box>
                <Box style={{display: 'flex'}}>
                    <Typography className={classes.sectionText}>Quote:</Typography>
                    {somethingSelected() && 
                    <img 
                    src={Trash} 
                    onClick={deleteSelected}
                    style={{marginLeft: 'auto', width: '20px', height: '20px', marginTop: 'auto', marginBottom: 'auto'}}>
                        </img>}
                </Box>
                <Divider style={{height: '1px', backgroundColor: 'black', marginBottom: '15px'}}></Divider>
                <Box >
                    {lineItems.map((l)=> {
                        return (<LineItemRow selected={selected} select={select} l={l}/>)
                    })}
                    <Divider style={{height: '1px', backgroundColor: 'black', width: '30vw', marginLeft: 'auto'}}></Divider>
                    <Box className={classes.totalContainer}>
                        <Typography className={classes.text}>Total</Typography>
                        <Typography className={classes.text} style={{marginLeft: 'auto'}}>${totalPrice()}</Typography>
                    </Box>
                </Box>
                <AddLineItem setLineItems={setLineItems} q={props.q} notifyChange={notifyChange}/>
                <Typography className={classes.sectionText}>Service Range:</Typography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={serviceRange}
                    onChange={handleServiceRangeChange}
                    style={{width: '100%', marginBottom: '20px', marginTop: '5px', paddingTop: '5px', paddingBottom: '5px'}}
                    >
                    <MenuItem value={'this week'}>this week</MenuItem>
                    <MenuItem value={'this weekend'}>this weekend</MenuItem>
                    <MenuItem value={'next week'}>next week</MenuItem>
                    <MenuItem value={'next weekend'}>next weekend</MenuItem>
                </Select>
                {showBeforePictureSection() && (
                    <PictureSection status={'before'} job_id={props.q.id} header={"Before Pictures:"}/>
                )}
                {showAfterPictureSection() && (
                    <PictureSection status={'after'} job_id={props.q.id} header={"After Pictures:"}/>
                )}
            </Box>
            {readyForApproval() && 
            <>
            <Box style={{height: '15vh'}}>
            </Box>
            <Box className={classes.actionContainerBackground}>
                <Box className={classes.actionContainer}>
                     <Box className={classes.callContainer} style={{margin: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                        <Typography className={classes.sectionText} onClick={submit}>{getSubmitText(props.q.status)}</Typography>
                    </Box>
                    </Box>
            </Box>
            </>}
            <Drawer
                anchor={'bottom'}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {modalMenu()}
            </Drawer>
            <Drawer
                anchor={'bottom'}
                open={newJob}
                onClose={() => setNewJob(false)}
            >
                {newJobModal()}
            </Drawer>
            {/* {shouldNotifyDateChange() && 
                <>
                <Box style={{height: '15vh'}}>
                </Box>
                <Box className={classes.actionContainerBackground}>
                <Box className={classes.actionContainer}>
                    <Box className={classes.callContainer} style={{margin: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                        <Typography className={classes.sectionText} >Notify service date</Typography>
                    </Box>
                </Box>
            </Box>
            </>} */}
          </Box>
        </Box>
      );

    return (
        <>
           <Grid container spacing={2} onClick={() => setOpen(true)}>
            {!props.customFormat &&
            <>
                <Grid item xs={5}>
                    <div style={{marginLeft: '20px'}}>
                        <Typography className={classes.rowText}>{props.q.Customer.address},</Typography>
                    </div>
                    <div style={{marginLeft: '20px'}}>
                        <Typography className={classes.rowText}>{props.q.Customer.city}, MA, {props.q.Customer.zipcode} </Typography>
                    </div>
                </Grid>
                <Grid item xs={7} style={{textAlign: 'center', margin: 'auto'}}>
                    <Typography className={classes.rowText}>{props.q.days} {props.q.days > 1 ? 'days': 'day'}</Typography>
                </Grid>
            </>
            }
            {
                props.customFormat && <>{props.format}</>
            }
            </Grid>
            <Divider style={{height: '1px', backgroundColor: 'black', marginBottom: '20px', marginTop: '20px'}}></Divider>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={handleClose}
            >
            {quoteDetails()}
          </Drawer>
        </>)
}

function QuoteRequested(props) {
    const classes = useStyles();
    const [quotes, setQuotes] = React.useState([]);

    React.useEffect(() => {
        updateData();
    }, [])

    const updateData = () => {
        GetQuotes(setQuotes);
    }

    return (
        <>
        <div className={classes.tableHeader}>
            <Grid container spacing={2}> 
                <Grid item xs={5}>
                    <Typography 
                    style={{marginLeft: '20px'}}
                    className={classes.tableColumnHeader}
                    >Address</Typography>
                </Grid>
                <Grid item xs={7} style={{textAlign: 'center'}}>
                    <Typography 
                    className={classes.tableColumnHeader} 
                    >Days Since Request</Typography>
                </Grid>
            </Grid>
        </div>
        <Divider style={{height: '1px', backgroundColor: 'black', marginBottom: '20px'}}></Divider>
        {quotes.map((q) => {
            return (
                <QuoteRequestedRow q={q} setQuotes={setQuotes} updateData={updateData}></QuoteRequestedRow>
            )
        })}
        </>
    )
}

export default QuoteRequested;