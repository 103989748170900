import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import uploadImage from '../Upload/upload-image.js';
import ImageSquare from '../image-sqaure';
import { GrCamera } from 'react-icons/gr';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import updateSheetValues from "../update-google-sheet";
import { GrClose } from 'react-icons/gr';
import upload_picture from '../Images/upload_pictures.png'

const useStyles = makeStyles(() => ({
  img: {
    width: '90vw',
    height: '90vw',
    borderRadius: '20px',
    objectFit: 'cover',
    dropShadow: '0 10px 5px lightblue',
  },
  imgContainer: {
    textAlign: 'center',
    paddingTop: '10px',
  },
  input: {
    display: 'none',
  },
  bottom: {
    position: 'absolute',
    bottom: '25px',
    right: '25px',
  },
  inputIcon: {
    margin: 'auto',
    display: 'block',
  },
  circle: {
    backgroundColor: 'lightblue',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    '&:focus': {
      borderRadius: '50%',
      backgroundColor: '#93c47d',
  }
  },
  container: {
    display: 'block',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  loadingContainer: {
    display: 'block',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  uploadStatusContainer: {
    display: 'block',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  modalContent: {
    display: 'block',
    position: 'absolute',
    overflowY: 'auto',
    width: '80%',
    height: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    borderRadius: '20px',
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
  },
  img: {
    marginTop: '10px',
    width: '90vw',
    height: '90vw',
    borderRadius: '20px',
    objectFit: 'cover',
    dropShadow: '0 10px 5px lightblue',
  },
  uploadContainer: {
    paddingTop: '20px',
    textAlign: 'center',
  },
  upload: {
    width: '90%'
  },
  exitContainer: {
    position: 'absolute',
    bottom: '25px',
    left: '25px',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    display: 'flex',
    backgroundColor: '#ff7f7f',
  },
  exitButton: {
    margin: 'auto',
  },
  successTitle: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  successButton: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  loadingProgress: {
    paddingTop: '100px',
    float: 'center',
    textAlign: 'center',
    margin: 'auto',
  },
  exitModalButton: {
    paddingTop: '5vw',
  }
  }));

function ImageFile(url, file) {
  this.url = url;
  this.file = file;
  this.progress = 1;
  this.uploaded = false;
}

function WebcamCapture(props) {
  const classes = useStyles();
  const [images, setImages] = React.useState([]);
  const [uploadedImages, setUploadedImages] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [exitModalOpen, setExitModalOpen] = React.useState(false);
  const [downloading, setDownloading] = React.useState(true);
  const [complete, setComplete] = React.useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Basic YXBpa2V5OnB1YmxpY19XMTQyaFZYeGc2YVd1NkVZUDRkVVY2QVNQSk5Y");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // UPLOAD IO TAG: must update folder for each snowstorm.
    var path = `${props.image_upload_path}${props.address.replaceAll(" ","")}`
    path = (props.type == "Complaint") ? path + "/Complaint" : path;
    fetch(`https://api.upload.io/v2/accounts/W142hVX/folders/children?folderPath=${path}`, requestOptions)
    .then(response => response.json().then((data) => {
      if (data != undefined) {
        let imgs = data.children.map(chil => chil.fileUrl);
        if (imgs != undefined && imgs[0] != undefined) {
          setUploadedImages(imgs);
        }
      }
      setDownloading(false);
    }))
    .catch(error => console.log('error', error));
  }, []);

  const handleImageChange = (event) => {
      let new_images = [...images];
      for(let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i]
        let url = URL.createObjectURL(file)
        let imagefile = new ImageFile(url, file);
        new_images.unshift(imagefile);
      }
      setImages(new_images);
  }

  const handleDeleteImage = (index) => {
    let newImages = [...images];
    newImages.splice(index,1)
    setImages(newImages);
  }

  const allComplete = () => {
    let complete = true;
    images.forEach((img) => {
      if (img.progress != 100) {
        complete=false;
      }
    })
    
    return complete;
  }

  const setProgress = (progress, index) => {
    let newImages = [...images];
    newImages[index].progress = progress
    setImages(newImages);
    if (allComplete()) {
      setComplete(true);
    }
  }

  const uploadImages = (event) => {
    if (images != undefined && images[0] != undefined) {
      setOpen(true);
      let length = images.length
      var path = `${props.image_upload_path}${props.address.replaceAll(" ","")}`
      path = (props.type == "Complaint") ? path + "/Complaint" : path;
      for (let i= 0; i < length; i++) {
        uploadImage(images[i].file, setProgress, i, props.address, props.type, path);
      }
    }
  }

  const closeExitAndUploadImages = () => {
    setExitModalOpen(false);
    uploadImages()
  }

  const imagesToUpload = () => {
    if (images.length > 0){
      return true;
    }
    return false;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRouteComplete = () => {
    setOpen(false);
    props.close();
    props.updateCache(props.address);
    updateSheetValues(props.address, props.type, props.driver, props.script_id);
    // props.refresh();
  };

  const noImages = () => {
    if (uploadedImages.length == 0 && images.length == 0) {
      return true;
    }
    return false;
  }

  const exit = (force) => {
    if (!force && images.length != 0) {
      console.log("Pictures that need to be uploaded");
      setExitModalOpen(true);

    }
    else {
      setExitModalOpen(false);
      props.close();
    }
  }


  return (
    <>
    {(downloading) ? (
      <div className={classes.loadingContainer}>
        <div className={classes.loadingProgress}>
        <CircularProgress />
        </div>
      </div>
    ):(
    <div className={classes.container}>
      <div className={classes.bottom}>
      <label>
        <input 
          multiple
          type="file" 
          accept="image/*" 
          onChange={handleImageChange}
          className={classes.input}/>
        <div className={classes.circle}>
          <GrCamera className={classes.inputIcon} size={50}/>
        </div>
      </label>
      </div>
      <div className={classes.exitContainer}>
        <GrClose size={50} className={classes.exitButton} onClick={() => exit(false)}/>
      </div>
      <div className={classes.uploadContainer}>
        { imagesToUpload() &&
        <Button className={classes.upload} onClick={uploadImages} variant="contained" color="success">
          Upload {images.length} new image(s)
        </Button>
        }
      </div>
      <div className={classes.imgContainer}>
      {noImages() && (
        <label>
          <input 
          multiple
          type="file" 
          accept="image/*" 
          onChange={handleImageChange}
          className={classes.input}/>
          <img src={upload_picture}>
          </img>
        </label>)}
        {images.map((img, index) => {
          return ( <ImageSquare img={img} index={index} delete={handleDeleteImage}/>)
        })}
        {uploadedImages.map((img) => {
          return ( <img src={img} className={classes.img}/>)
        })}
      </div>
      <Modal
            hideBackdrop
            open={open}
        >
          <div className={classes.uploadStatusContainer}>
            <div className={classes.modalContent}>
            <h2>Uploading Pictures</h2>
                {(complete) ? (
                  <div>
                    <Typography className={classes.successTitle}>
                      Pictures have successfully been uploaded!
                    </Typography>
                    <Button 
                      className={classes.successButton}
                      variant="contained" 
                      color="success"
                      onClick={handleRouteComplete}
                      >
                      I Confirm Route is Complete
                    </Button>
                  </div>
              ) :
              (
                images.map((img, index) => {
              return ( 
                <div className={classes.center}>
                  <Typography >
                  Picture {index+1}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10%' }}>
                    <Box sx={{ width: '80%', mr: 1 }}>
                      <LinearProgress variant="determinate" value={img.progress} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">{`${Math.round(
                        img.progress,
                      )}%`}</Typography>
                    </Box>
                  </Box>
                </div>)})
              )}
            </div>
          </div>
      </Modal>
      <Modal
            hideBackdrop
            open={exitModalOpen}
        >
          <div className={classes.uploadStatusContainer}>
            <div className={classes.modalContent}>
            <h2>Exiting Picture Upload</h2>
                  <div>
                    <Typography className={classes.successTitle}>
                      There are pictures that have not been uploaded.
                    </Typography>
                    <Button 
                      className={classes.successButton}
                      variant="contained" 
                      color="success"
                      onClick={closeExitAndUploadImages}
                      >
                      Upload Now
                    </Button>
                    <div className={classes.exitModalButton}>
                      <Button 
                        className={classes.successButton}
                        variant="contained" 
                        color="error"
                        onClick={() => exit(true)}
                        >
                        Exit Without Uploading
                      </Button>
                    </div>
                  </div>
            </div>
          </div>
      </Modal>
    </div>)
  }
  </>
  );
};

export default WebcamCapture;
