import React from 'react';
import RouteViewPage from "../Pages/route-view-page";
import {useParams} from "react-router-dom"
import { LinearProgress } from '@mui/material';
import GetEvent from "../Drive/get-event";

function Snow() {
    const data_sheet_id = '1wDN_k0YUtWsE2y9atMa55yZK-XNfShYONRPRDmxUKqo';
    const data_csv_url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRuJ_WLEABnqKIgysFxD0J7I9LIscsCO3yA--uJYfbyOG9X-_yMzhpGFIS4fVIj8h5Yqj-ooqo3DZrZ/pub?output=csv';
    const script_id = "AKfycbyJJmEOoEO13U54DmIE6SsDrxf0_Glrau4VfdtHFXrFXP5TJa-kVeuvH4gmEXdjymgy";
    // ACTION: Change to this path requires update to public snow routes sheet getImages script
    const base_image_upload_path = '/uploads/${event}/snow/properties/';
    const [image_upload_path, setImageUploadPath] = React.useState("");

    React.useEffect(() => {
        GetEvent(script_id, handleUpdateEvent)
    }, [])

    const handleUpdateEvent = (result) => {
        if (result.succeeded) {
            let newPath = base_image_upload_path.replace("${event}", result.event)
            setImageUploadPath(newPath);
        }
    }

    let params = useParams();
    let id = params.id.replaceAll("_", " ");

    return (
        <>
        {image_upload_path == "" ? (
            <LinearProgress />
        ):(
            <RouteViewPage id={id} data_sheet_id={data_sheet_id} data_csv_url={data_csv_url} script_id={script_id} image_upload_path={image_upload_path}/>
        )}
        </>
        
    )
}

export default Snow