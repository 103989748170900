import { ClassNames } from '@emotion/react';
import React from 'react';
import { render } from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Carousel, { CarouselItem } from "./carousel";

const useStyles = makeStyles(() => ({
    gallery: {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
    },
    }));

function SwipeableImageGallery(props) {
    const classes = useStyles();
    // const imgs = ["https://drive.google.com/uc?export=view&id=1qIRJ0yd_-itPLPwJJxa6-V7Jno4duA6l", "https://drive.google.com/uc?export=view&id=11YhQYHlgz5ephouNWE8KhpMgkL1pwp8A", "https://drive.google.com/uc?export=view&id=1JOAq7GzSyrBa4PCBIaTD7rLJszaVNsxN"]
    
    return (
      <div>
        <Carousel images={props.images}>
        {props.images.map((img) => {
          return (<CarouselItem><img className={classes.gallery} src={img}/></CarouselItem>)
        })}
        </Carousel>
      </div>
      
    )
}
  
export default SwipeableImageGallery;