import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Snow from './Components/Services/snow';
import Cleanup from './Components/Services/cleanup';
import Pictures from './Components/Services/pictures';
import Landscaper from './Components/Pages/landscaper';
import LandscaperAdmin from './Components/Pages/landscaper-admin';
import Lawns from './Components/Services/lawns';
import Salt from './Components/Services/salt';
import Partners from './Components/Pages/partners';
import PartnerFormSubmissionSuccessful from './Components/Pages/partner-form-submitted';


const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter >
      <Routes>
        <Route path="/route/:id" element={<Snow/>} />
        <Route path="/cleanups/:id" element={<Cleanup/>} />
        <Route path="/lawns/:id" element={<Lawns/>} />
        <Route path="/salt/:id" element={<Salt/>} />
        <Route path="/pictures/:service/:storm/:address" element={<Pictures/>} />
        <Route path="/landscaping" element={<Landscaper/>}/>
        <Route path="/landscaping/admin" element={<LandscaperAdmin/>}/>
        <Route path="/partners" element={<Partners/>}/>
        <Route path="/submissionsuccessful" element={<PartnerFormSubmissionSuccessful/>}/>
      </Routes>
    </BrowserRouter>
);

// ReactDOM.render(
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<App/>} />
//       </Routes>
//     </BrowserRouter>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
