const WeeklyIncome = async (handleDone) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };
    var response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/weeklyIncome`, requestOptions)

    let result = await response.json();
    handleDone(result);
    return result;
}

export default WeeklyIncome;